<template>
  <LayoutPillComponent
    :localizedData="data"
    :type="linkType"
    componentType="linkWithFallback"
    name="focus-week-slug"
    sizeOption="narrow"
    :style="styleString"
  >
    {{ title }}
  </LayoutPillComponent>
</template>

<script setup>
const props = defineProps({
  data: Object,
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  },
  useClusterBackgroundColor: {
    type: Boolean,
    default: false
  }
})

const title = computed(() => i18nGetLocalizedTitle(props?.data))

const backgroundColor = computed(() => {
  if (!props?.useClusterBackgroundColor) return null
  return props?.data?.cluster_entry?.data?.attributes?.background_color || 'var(--color--blue)'
})

const borderColor = computed(() => {
  if (!props?.useClusterBackgroundColor && !backgroundColor) return null
  return 'transparent'
})

const styleString = computed(() => {
  const bg = props?.useClusterBackgroundColor ? backgroundColor.value : null
  const bd = props?.useClusterBackgroundColor ? borderColor.value : null
  const backgroundColorString = bg ? `--pill--background-color: ${bg};` : ''
  const borderColorString = bd ? `--pill--border-color: ${bd};` : ''
  return [backgroundColorString, borderColorString]?.filter(Boolean)?.join('; ')
})
</script>
