<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="participant"
  >
    <template #header>
      <LayoutTagsComponent
        v-if="venues?.length || cities?.length || clusters?.length"
        :class="$style.tags"
      >
        <LayoutTagsGroupComponent
          v-if="clusters?.length"
          :label="$t('label.cluster', { count: clusters?.length })"
        >
          <SharedLinkToClusterComponent
            v-for="cluster in clusters"
            :key="cluster?.id"
            :data="cluster"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="cities?.length"
          :label="$t('label.city', { count: cities?.length })"
        >
          <SharedLinkToCityComponent
            v-for="city in cities"
            :key="city?.id"
            :data="city"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="venues?.length"
          :label="$t('label.venue', { count: venues?.length })"
        >
          <SharedLinkToVenueComponent
            v-for="venue in venues"
            :key="venue?.id"
            :data="venue"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>
      </LayoutTagsComponent>

      <LayoutTagsComponent
        v-if="localizedData?.place_of_residence || localizedData?.place_born || localizedData?.year_born"
        :class="$style.tags"
      >
        <LayoutTagsGroupComponent
          v-if="placeOfResidence"
          :class="$style.tagGroup"
          :label="$t('label.cityOfResidence')"
        >
          <span :class="$style.info">{{ placeOfResidence }}</span>
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="placeBorn"
          :class="$style.tagGroup"
          :label="$t('label.placeOfBirth')"
        >
          <span :class="$style.info">{{ placeBorn }}</span>
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="localizedData?.year_born"
          :class="$style.tagGroup"
          :label="$t('label.yearOfBirth')"
        >
          <span :class="$style.info">{{ localizedData?.year_born }}</span>
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="localizedData?.year_of_death"
          :class="$style.tagGroup"
          :label="$t('label.yearOfDeath')"
        >
          <span :class="$style.info">{{ localizedData?.year_of_death }}</span>
        </LayoutTagsGroupComponent>
      </LayoutTagsComponent>
    </template>

    <template #content>
      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template #footer>
      <LayoutSliderComponent
        v-if="events?.length"
        :component="SharedThumbnailEventComponent"
        :entries="events"
        :label="$t('label.event', { count: event?.length })"
        :withinModal="true"
        linkType="dynamic"
      />

      <LayoutSliderComponent
        v-if="news?.length"
        :component="SharedThumbnailNewsComponent"
        :entries="news"
        :label="$t('shared.relatedType', {type: $t('label.news', { count: news?.length })?.toLowerCase()})"
        :withinModal="true"
        linkType="dynamic"
      />

    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
import { SharedThumbnailNewsComponent } from '#components'
import { SharedThumbnailEventComponent } from '#components'

import { uniqBy } from 'lodash-es'

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'participants',
  }
})

// 1
const localizedData = computed(() => i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data)) )

const placeOfResidence = computed(() => i18nGetLocalizedString(localizedData?.value?.place_of_residence) )

const placeBorn = computed(() => i18nGetLocalizedString(localizedData?.value?.place_born) )

const cities = computed(() => localizedData?.value?.venue_entries?.data?.map(i => i?.attributes?.city_entry?.data?.attributes)?.filter(Boolean) )

const venues = computed(() => localizedData?.value?.venue_entries?.data?.map(i => i?.attributes)?.filter(Boolean) )

const clusters = computed(() => uniqBy(localizedData?.value?.venue_entries?.data?.map(i => i?.attributes?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes)?.filter(Boolean), ['background_color']) )

const events = computed(() => localizedData?.value?.event_entries?.data )

const news = computed(() => localizedData?.value?.news_entries?.data )

// 2
const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.tags {
  margin-top: var(--unit--vertical);
}

.info  {
  composes: font-size-x-small from global;
  padding-top: var(--padding--button-narrow--top);
  text-transform: uppercase;
}
</style>
