<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="cluster"
  >
    <template #header>
      <div
        v-if="localizedData?.lat && localizedData?.long"
        :class="$style.showOnMapContainer"
      >
        <LayoutPillComponent
          :to="{name: 'map', query: {lat: localizedData?.lat, lng: localizedData?.long, zoom: mapStore?.zoom?.cluster}}"
          viewOption="primary"
          sizeOption="narrow"
          component-type="link"
        >
          {{ $t('map.label.showOnMap') }}
        </LayoutPillComponent>
      </div>
    </template>

    <template #content>
      <LayoutCoverImageComponent
        v-if="localizedData?.cover_image?.data?.attributes"
        :image="localizedData?.cover_image?.data?.attributes"
      />

      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template #footer>
      <PagesClustersSlugCitiesComponent
        :localizedData="localizedData"
        :class="$style.cities"
      />

      <LayoutSliderComponent
        v-if="news?.length"
        :label="$t('shared.relatedType', {type: $t('label.news', { count: news?.length })?.toLowerCase()})"
        :entries="news"
        :component="SharedThumbnailNewsComponent"
        linkType="dynamic"
        :showClusters="false"
        :withinModal="true"
      />

      <LayoutPillComponent
        componentType="link"
        :to="{name: 'clusters'}"
        :class="$style.linkToAll"
        viewOption="primary"
      >
        {{ $t('shared.seeAllOfType', {type: $t('label.cluster', { count: 2 }) }) }}
      </LayoutPillComponent>
    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
const mapStore = useMapStore()
import { SharedThumbnailNewsComponent } from '#components'

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'clusters',
  }
})

// 1
const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const news = computed(() => {
  return localizedData?.value?.news_entries?.data
})

// 2
const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.showOnMapContainer {
  margin-top: var(--unit--vertical);
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);
}

.cities {
  padding: 0 calc(var(--unit--horizontal) * 2);
}

.linkToAll {
  margin-left: calc(var(--unit--horizontal) * 2);
  margin-right: auto;
}
</style>
