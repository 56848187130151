<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="news"
  >
    <template #header>
      <CoreDateTimeRangeComponent
        v-if="localizedData?.date_posted"
        :dateStart="localizedData?.date_posted"
        :class="$style.dateRange"
        format="DD.MM.YYYY"
        :includeTime="false"
      />

      <LayoutCollectionTypeHeaderTagsComponent
        v-if="clusters?.length"
      >
        <SharedLinkToClusterComponent
          v-for="cluster in clusters"
          :key="cluster?.id"
          :data="cluster?.attributes"
          linkType="dynamic"
        />
      </LayoutCollectionTypeHeaderTagsComponent>
    </template>
    <template #content>
      <LayoutCoverImageComponent
        v-if="localizedData?.cover_image?.data?.attributes"
        :image="localizedData?.cover_image?.data?.attributes"
      />

      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template #footer
      v-if="events?.length || participants?.length"
    >
      <LayoutSliderComponent
        v-if="events?.length"
        :label="$t('label.event', { count: events?.length })"
        :entries="events"
        :component="SharedThumbnailEventComponent"
        linkType="dynamic"
        :withinModal="true"
      />

      <LayoutSliderComponent
        v-if="participants?.length"
        :label="$t('label.participant', { count: participants?.length })"
        :entries="participants"
        linkType="dynamic"
        :component="SharedThumbnailParticipantComponent"
        :withinModal="true"
      />
    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
import { SharedThumbnailEventComponent, SharedThumbnailParticipantComponent } from '#components'

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'news',
  }
})

// 1
const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const clusters = computed(() => {
  return localizedData?.value?.cluster_entries?.data
})

const events = computed(() => {
  return localizedData?.value?.event_entries?.data
})

const participants = computed(() => {
  return localizedData?.value?.participant_entries?.data
})

// 2
const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.dateRange {
  color: var(--color--blue);
  display: block;
  margin-top: calc((var(--font-small--line-height) - (var(--font-default--line-height) - var(--font-default--font-size))) / 2);
}
</style>
