<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="event"
  >
    <template #header>
      <CoreDateTimeRangeComponent
        v-if="localizedData?.date_time_start"
        :dateStart="localizedData?.date_time_start"
        :dateEnd="localizedData?.date_time_end"
        :class="$style.dateRange"
      />

      <div
        v-if="venue?.lat && venue?.long"
        :class="$style.showOnMapContainer"
      >
        <LayoutPillComponent
          :to="{name: 'map', query: {lat: venue?.lat, lng: venue?.long, zoom: mapStore?.zoom?.venue}}"
          viewOption="primary"
          sizeOption="narrow"
          component-type="link"
        >
          {{ $t('map.label.showOnMap') }}
        </LayoutPillComponent>
      </div>

      <div
        v-else-if="localizedData?.event_locations?.data?.length && localizedData?.event_locations?.data?.length < 4"
        :class="$style.showOnMapContainer"
      >
        <LayoutPillComponent
          v-for="location in localizedData?.event_locations?.data"
          :key="location?.id"
          :to="{name: 'map', query: {lat: location?.attributes?.lat, lng: location?.attributes?.long, zoom: mapStore?.zoom?.eventLocation}}"
          viewOption="primary"
          sizeOption="narrow"
          component-type="link"
        >
          {{ $t('map.label.showOnMap') }}
        </LayoutPillComponent>
      </div>

      <div
        v-else-if="focusWeek?.lat && focusWeek?.long"
        :class="$style.showOnMapContainer"
      >
        <LayoutPillComponent
          :to="{name: 'map', query: {lat: focusWeek?.lat, lng: focusWeek?.long, zoom: mapStore?.zoom?.focusWeek}}"
          viewOption="primary"
          sizeOption="narrow"
          component-type="link"
        >
          {{ $t('map.label.showOnMap') }}
        </LayoutPillComponent>
      </div>

      <LayoutTagsComponent
        :class="$style.tags"
        v-if="venue?.lat && venue?.long && venue?.address || eventLocationAddresses?.length || focusWeek?.address && focusWeek?.lat && focusWeek?.long"
      >
        <LayoutTagsGroupComponent
          :label="$t('map.label.address')"
        >
          <div
            :class="$style.address"
            v-if="venue?.lat && venue?.long && venue?.address"
          >
            <CoreTextComponent
              :string="localizedString(venue?.address)"
            />

            <a
              :href="`https://www.google.com/maps/search/?api=1&query=${venue?.lat},${venue?.long}`"
              target="_blank"
              :class="$style.linkToDirection"
            >
              Google Maps ↗
            </a>
          </div>

          <template
            v-else-if="eventLocationAddresses?.length"
          >
            <div
              :class="$style.address"
              v-for="address in eventLocationAddresses"
              :key="address?.address"
            >
              <CoreTextComponent
                :string="address?.address"
              />

              <a
                :href="`https://www.google.com/maps/search/?api=1&query=${address?.lat},${address?.long}`"
                target="_blank"
                :class="$style.linkToDirection"
              >
                Google Maps ↗
              </a>
            </div>
          </template>

          <div
            :class="$style.address"
            v-else-if="focusWeek?.address && focusWeek?.lat && focusWeek?.long"
          >
            <CoreTextComponent
              :string="localizedString(focusWeek?.address)"
            />

            <a
              :href="`https://www.google.com/maps/search/?api=1&query=${focusWeek?.lat},${focusWeek?.long}`"
              target="_blank"
              :class="$style.linkToDirection"
            >
              Google Maps ↗
            </a>
          </div>
        </LayoutTagsGroupComponent>
      </LayoutTagsComponent>

      <LayoutTagsComponent
        v-if="participants?.length || venue || city || cluster || categories?.length"
        :class="$style.tags"
      >
        <LayoutTagsGroupComponent
          v-if="cluster"
          :label="$t('label.cluster')"
        >
          <SharedLinkToClusterComponent
            v-if="cluster"
            :data="cluster"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="categories?.length"
          :label="$t('label.category', { count: categories?.length })"
        >
          <LayoutPillComponent
            v-for="category in categories"
            :key="category?.id"
            componentType="link"
            sizeOption="narrow"
            :to="{
              name: 'events',
              query: {
                categories: [category?.id]
              }
            }"
          >
            {{ category?.title }}
          </LayoutPillComponent>
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="participants?.length"
          :label="$t('label.participant', { count: categories?.length })"
        >
          <SharedLinkToParticipantComponent
            v-for="participant in participants"
            :key="participant?.id"
            :data="participant?.attributes"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>
        <LayoutTagsGroupComponent
          v-if="venue"
          :label="$t('label.venue')"
        >
          <SharedLinkToVenueComponent
            :data="venue"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>
        <LayoutTagsGroupComponent
          v-if="city"
          :label="$t('label.city')"
        >
          <SharedLinkToCityComponent
            :data="city"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="focusWeek"
          :label="$t('label.focusWeek')"
        >
          <SharedLinkToFocusWeekComponent
            :data="focusWeek"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>
      </LayoutTagsComponent>
    </template>

    <template #content>
      <LayoutCoverImageComponent
        v-if="localizedData?.cover_image?.data?.attributes"
        :image="localizedData?.cover_image?.data?.attributes"
      />

      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template
      #footer
      v-if="news?.length"
    >
      <LayoutSliderComponent
        :label="$t('shared.relatedType', {type: $t('label.news', { count: news?.length })?.toLowerCase()})"
        :entries="news"
        linkType="dynamic"
        :component="SharedThumbnailNewsComponent"
        :withinModal="true"
      />
    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
const mapStore = useMapStore()
import { SharedThumbnailNewsComponent } from '#components'
const localizedString = i18nGetLocalizedString

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'events',
  }
})

// 1
const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const eventLocationAddresses = computed(() => {
  return localizedData?.value?.event_locations?.data?.map(i => {
    return {
      long: i?.attributes?.long,
      lat: i?.attributes?.lat,
      address: i18nGetLocalizedString(i?.attributes?.address)
    }
  })
})

const participants = computed(() => {
  return localizedData?.value?.participant_entries?.data
})

const venue = computed(() => {
  return localizedData?.value?.venue_entry?.data?.attributes
})

const city = computed(() => {
  return localizedData?.value?.venue_entry?.data?.attributes?.city_entry?.data?.attributes ||
            localizedData?.value?.focus_week_entry?.data?.attributes?.city_entry?.data?.attributes ||
            localizedData?.value?.event_locations?.data?.map(i => i?.attributes?.city_entry?.data?.attributes)?.filter(Boolean)?.[0]
})

const cluster = computed(() => {
  const clusterFromVenueData = i18nGetLocalizedData(localizedData?.value?.venue_entry?.data?.attributes)?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
  const clusterFromEventLocationData = localizedData?.value?.event_locations?.data?.map(i => i?.attributes?.cluster_entry?.data?.attributes)?.filter(Boolean)?.[0]
  const clusterFromFocusWeekData = localizedData?.value?.focus_week_entry?.data?.attributes?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
  return clusterFromVenueData || clusterFromEventLocationData || clusterFromFocusWeekData
})

const focusWeek = computed(() => {
  return localizedData?.value?.focus_week_entry?.data?.attributes
})

const categories = computed(() => {
  return localizedData?.value?.event_categories?.data?.map((category) => {
    return {
      title: i18nGetLocalizedString(category?.attributes?.titles),
      id: category?.id
    }
  })?.filter(i => i?.title)
})

const news = computed(() => {
  return localizedData?.value?.news_entries?.data
})

// 2
const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.dateRange {
  color: var(--color--blue);
  display: block;
  margin-top: calc((var(--font-small--line-height) - (var(--font-default--line-height) - var(--font-default--font-size))) / 2);
}

.showOnMapContainer {
  margin-top: var(--unit--vertical);
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);
}

.tags {
  margin-top: var(--font-x-small--line-height);
}

.address {
  composes: font-size-small from global;
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);
}

.linkToDirection {
  composes: reset-link link from global;
  color: var(--color--blue);
}
</style>
