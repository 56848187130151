import qs from 'qs'

const fields = [
  'date_time_start',
  'date_time_end'
]

export const queryEventEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slugs: {
          [searchKey]: {
            $eq: options.slug
          }
        }
      },
      fields,
      populate: {
        cover_image: true,
        event_categories: {
          populate: {
            titles: true
          }
        },
        // no city_entry
        event_locations: {
          populate: {
            address: true,
            cluster_entry: {
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            },
            ...queryFragmentCollectionTypeDefault,
          }
        },
        focus_week_entry: {
          populate: {
            address: true,
            city_entry: {
              populate: {
                cluster_entry: {
                  populate: {
                    ...queryFragmentCollectionTypeDefault,
                  }
                }
              }
            },
            ...queryFragmentCollectionTypeDefault,
          }
        },
        venue_entry: {
          popylate: queryFragmentCollectionTypeDefault
        },
        participant_entries: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
          }
        },
        ...queryFragmentCollectionTypeDefault,
        ...queryFragmentBlocks,
        ...queryFragmentNewsEntries,
        ...queryFragmentSeo,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `event-entries?${query}`
}
