import qs from 'qs'

const fields = [
  'title',
  'year_born',
  'year_of_death',
]

export const queryParticipantEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slug: {
          $eq: options.slug
        }
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
        ...queryFragmentEventEntries,
        ...queryFragmentNewsEntries,
        ...queryFragmentBlocks,
        ...queryFragmentSeo,
        venue_entries: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
            city_entry: {
              ...queryFragmentCollectionTypeDefault,
            }
          }
        },
        place_of_residence: true,
        place_born: true
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `participant-entries?${query}`
}
