<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style module lang=scss>
.root {
  margin-top: var(--unit--vertical);

  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);

  @media (max-width: $breakpoint-s) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
