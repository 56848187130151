import qs from 'qs'

export const queryCityEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slugs: {
          [searchKey]: {
            $eq: options.slug
          }
        }
      },
      populate: {
        ...queryFragmentCollectionTypeDefault,
        cluster_entry: {
          fields: ['background_color'],
          populate: {
            ...queryFragmentCollectionTypeDefault,
          }
        },
        cover_image: true,
        tags: {
          populate: {
            labels: true,
            descriptions: true
          }
        },
        ...queryFragmentBlocks,
        ...queryFragmentSeo,
        ...queryFragmentVenueEntries,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `city-entries?${query}`
}
