<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="publication"
  >
    <template #header>
      <div
        v-if="price"
        :class="$style.price"
      >
        {{price}}
      </div>
    </template>

    <template #content>
      <LayoutCoverImageComponent
        v-if="coverImage"
        :image="coverImage"
      />

      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template #footer>
      <LayoutSliderComponent
        v-if="events?.length"
        :label="$t('label.event', { count: events?.length })"
        :entries="events"
        :component="SharedThumbnailEventComponent"
        linkType="dynamic"
        :withinModal="true"
      />

      <LayoutPillComponent
        componentType="link"
        :to="{name: 'publications'}"
        viewOption="primary"
        :class="$style.linkToAll"
      >
        {{ $t('shared.seeAllOfType', {type: $t('label.publication', { count: 2 }) }) }}
      </LayoutPillComponent>
    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
const { locale: currentLocale } = useI18n()
import { SharedThumbnailEventComponent, SharedThumbnailParticipantComponent } from '#components'

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'venues',
  }
})

// 1
const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})


const events = computed(() => {
  return localizedData?.value?.event_entries?.data
})

const price = computed(() => i18nGetLocalizedString(localizedData?.value?.prices, 'i18n_string_en'))
const coverImage = computed(() => {
  if (currentLocale.value === 'en') {
    return localizedData.value?.i18n_cover_image_en?.data?.attributes
  } else if (currentLocale.value === 'es') {
    return localizedData.value?.i18n_cover_image_es?.data?.attributes
  } else if (currentLocale.value === 'ca') {
    return localizedData.value?.i18n_cover_image_ca?.data?.attributes
  }
})

// 2
// const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
// pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.price {
  color: var(--color--blue);
  display: block;
  margin-top: calc((var(--font-small--line-height) - (var(--font-default--line-height) - var(--font-default--font-size))) / 2);
}

.linkToAll {
  margin-left: calc(var(--unit--horizontal) * 2);
  margin-right: auto;
}
</style>
