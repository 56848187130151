<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="city"
  >
    <template #header>
      <div
        v-if="localizedData?.lat && localizedData?.long"
        :class="$style.showOnMapContainer"
      >
        <LayoutPillComponent
          :to="{name: 'map', query: {lat: localizedData?.lat, lng: localizedData?.long, zoom: mapStore?.zoom?.city}}"
          viewOption="primary"
          sizeOption="narrow"
          component-type="link"
        >
          {{ $t('map.label.showOnMap') }}
        </LayoutPillComponent>
      </div>

      <LayoutTagsComponent
        v-if="cluster || tags?.length"
        :class="$style.tags"
      >
        <LayoutTagsGroupComponent
          v-if="cluster"
          :label="$t('label.cluster')"
        >
          <SharedLinkToClusterComponent
            v-if="cluster"
            :data="cluster"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>

        <template
          v-if="tags?.length"
          v-for="tag in tags"
          :key="tag?.label"
        >
          <LayoutTagsGroupComponent
            :label="tag?.label"
          >
            <span :class="$style.tagDescription">{{ tag?.description }}</span>
          </LayoutTagsGroupComponent>
        </template>
      </LayoutTagsComponent>
    </template>

    <template #content>
      <LayoutCoverImageComponent
        v-if="localizedData?.cover_image?.data?.attributes"
        :image="localizedData?.cover_image?.data?.attributes"
      />

      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template #footer>
      <LayoutSliderComponent
        v-if="venues?.length"
        :label="$t('label.venuesInCity', { count: venues?.length, city: title })"
        :entries="venues"
        :component="SharedThumbnailVenueComponent"
        linkType="dynamic"
        :withinModal="true"
      />

      <LayoutPillComponent
        componentType="link"
        :to="{name: 'cities'}"
        :class="$style.linkToAll"
        viewOption="primary"
      >
        {{ $t('shared.seeAllOfType', {type: $t('label.city', { count: 2 }) }) }}
      </LayoutPillComponent>
    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
const mapStore = useMapStore()
import { SharedThumbnailVenueComponent } from '#components'

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'cities',
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const cluster = computed(() => {
  return localizedData?.value?.cluster_entry?.data?.attributes
})

const venues = computed(() => {
  return localizedData?.value?.venue_entries?.data
})

const tags = computed(() => {
  return localizedData?.value?.tags?.map((i) => {
    return {
      label: i18nGetLocalizedString(i?.labels),
      description: i18nGetLocalizedString(i?.descriptions)
    }
  })
})

// 2
const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.showOnMapContainer {
  margin-top: var(--unit--vertical);
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);
}

.tags {
  margin-top: var(--unit--vertical);
}

.tagDescription {
  composes: font-size-x-small from global;
  padding-top: var(--padding--button-narrow--top);
  text-transform: uppercase;
}

.linkToAll {
  margin-left: calc(var(--unit--horizontal) * 2);
  margin-right: auto;
}
</style>
