import cluster from 'cluster'
import qs from 'qs'

const fields = [
  'date_posted'
]

export const queryNewsEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slugs: {
          [searchKey]: {
            $eq: options.slug
          }
        }
      },
      fields,
      populate: {
        cover_image: true,
        cluster_entries: {
          fields: ['background_color'],
          populate: queryFragmentCollectionTypeDefault
        },
        ...queryFragmentCollectionTypeDefault,
        ...queryFragmentEventEntries,
        ...queryFragmentParticipantEntries,
        ...queryFragmentBlocks,
        ...queryFragmentSeo,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `news-entries?${query}`
}
