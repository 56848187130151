import qs from 'qs'

export const queryPublicationsEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slugs: {
          [searchKey]: {
            $eq: options.slug
          }
        }
      },
      populate: {
        ...queryFragmentCollectionTypeDefault,
        ...queryFragmentBlocks,
        ...queryFragmentSeo,
        i18n_cover_image_en: true,
        i18n_cover_image_es: true,
        i18n_cover_image_ca: true,
        prices: true,
        cover_image: true
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `publications-entries?${query}`
}
