<template>
  <div :class="$style.root">
    <template
      v-for="(network, index) in networks"
      :key="network"
    >
      <SocialShare
        :data-label="network.label"
        :network="network.value"
        :styled="false"
        :label="false"
        :class="$style.social"
      />{{ index < networks.length - 1 ? ', ' : '' }}
    </template>
  </div>
</template>

<script setup>
  const networks = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'twitter', label: 'X' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'email', label: 'Email' }
  ]
</script>

<style module>
.root {
}

.social {
  display: inline-block;
}

.social {
  text-decoration-line: none;
}

.social:hover {
  text-decoration-line: underline;
}

.social:before {
  content: attr(data-label);
}

.social svg {
  display: none;
}
</style>
