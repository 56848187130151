import cluster from 'cluster'
import qs from 'qs'

const fields = [
  'long',
  'lat'
]

export const queryVenueEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slugs: {
          [searchKey]: {
            $eq: options.slug
          }
        }
      },
      fields,
      populate: {
        cover_image: true,
        address: true,
        accessibility_types: {
          populate: queryFragmentCollectionTypeDefault
        },
        opening_times: true,
        ticket_url: true,
        ...queryFragmentCollectionTypeDefault,
        city_entry: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cluster_entry: {
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            }
          }
        },
        ...queryFragmentParticipantEntries,
        ...queryFragmentEventEntries,
        ...queryFragmentBlocks,
        ...queryFragmentSeo,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `venue-entries?${query}`
}
