<template>
  <LayoutCollectionTypeLayoutComponent
    :parentRoute="parentRoute"
    :data="localizedData"
    i18nPrefix="venue"
  >
    <template #header>
      <div
        v-if="localizedData?.lat && localizedData?.long"
        :class="$style.showOnMapContainer"
      >
        <LayoutPillComponent
          :to="{name: 'map', query: {lat: localizedData?.lat, lng: localizedData?.long, zoom: mapStore?.zoom?.venue}}"
          viewOption="primary"
          sizeOption="narrow"
          component-type="link"
        >
          {{ $t('map.label.showOnMap') }}
        </LayoutPillComponent>
      </div>

      <LayoutTagsComponent
        v-if="city || cluster"
        :class="$style.tags"
      >
        <LayoutTagsGroupComponent
          v-if="cluster"
          :label="$t('label.cluster')"
        >
          <SharedLinkToClusterComponent
            :data="cluster"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="city"
          :label="$t('label.city')"
        >
          <SharedLinkToCityComponent
            :data="city"
            linkType="dynamic"
          />
        </LayoutTagsGroupComponent>
      </LayoutTagsComponent>

      <LayoutTagsComponent
        :class="$style.tags"
        v-if="address && localizedData?.lat && localizedData?.long || localizedData?.opening_times?.length || accessibilityTypesString"
      >
        <LayoutTagsGroupComponent
          v-if="address && localizedData?.lat && localizedData?.long"
          :label="$t('map.label.address')"
        >
          <div :class="$style.address">
            <CoreTextComponent
              :string="address"
            />

            <a
              :href="`https://www.google.com/maps/search/?api=1&query=${localizedData?.lat},${localizedData?.long}`"
              target="_blank"
              :class="$style.linkToDirection"
            >
              Google Maps ↗
            </a>
          </div>
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          v-if="localizedData?.opening_times?.length"
          :label="$t('map.label.openingTimes')"
        >
          <SharedOpeningTimesComponent
            class="font-size-small"
            :openingTimes="localizedData?.opening_times"
          />
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          :label="$t('map.label.tickets')"
        >
          <a
            :href="ticketUrl"
            target="_blank"
            :class="$style.linkToTickets"
            v-if="ticketUrl"
          >
            {{ $t('map.label.buyTickets') }}
          </a>
          <span
            v-else
            class="font-size-small"
          >
            {{ $t('map.label.noTicket') }}
          </span>
        </LayoutTagsGroupComponent>

        <LayoutTagsGroupComponent
          :label="$t('map.label.accessibility')"
          v-if="accessibilityTypesString"
        >
          <span class="font-size-small">
            {{ accessibilityTypesString }}
          </span>
        </LayoutTagsGroupComponent>
      </LayoutTagsComponent>
    </template>

    <template #content>
      <LayoutCoverImageComponent
        v-if="localizedData?.cover_image?.data?.attributes"
        :image="localizedData?.cover_image?.data?.attributes"
      />

      <CoreBlocksComponent
        :blocks="localizedData?.blocks"
        :withinModal="true"
      />
    </template>

    <template #footer>
      <LayoutSliderComponent
        v-if="events?.length"
        :label="$t('label.event', { count: events?.length })"
        :entries="events"
        :component="SharedThumbnailEventComponent"
        linkType="dynamic"
        :withinModal="true"
      />

      <LayoutSliderComponent
        v-if="participants?.length"
        :label="$t('label.participant', { count: participants?.length })"
        :entries="participants"
        :component="SharedThumbnailParticipantComponent"
        linkType="dynamic"
        :withinModal="true"
      />

      <LayoutPillComponent
        componentType="link"
        :to="{name: 'venues'}"
        viewOption="primary"
        :class="$style.linkToAll"
      >
        {{ $t('shared.seeAllOfType', {type: $t('label.venue', { count: 2 }) }) }}
      </LayoutPillComponent>
    </template>
  </LayoutCollectionTypeLayoutComponent>
</template>

<script setup>
const mapStore = useMapStore()
import { SharedThumbnailEventComponent, SharedThumbnailParticipantComponent } from '#components'

const props = defineProps({
  pageData: Object,
  parentRoute: {
    type: [String, Object],
    default: 'venues',
  }
})

// 1
const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const city = computed(() => {
  return localizedData?.value?.city_entry?.data?.attributes
})

const cluster = computed(() => {
  return localizedData?.value?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
})

const events = computed(() => {
  return localizedData?.value?.event_entries?.data
})

const participants = computed(() => {
  return localizedData?.value?.participant_entries?.data
})

const accessibilityTypesString = computed(() => {
  return localizedData?.value?.accessibility_types?.data?.map((type) => {
    return i18nGetLocalizedString(type?.attributes?.titles)
  })?.filter(Boolean)?.join(', ')
})

const address = computed(() => i18nGetLocalizedString(localizedData?.value?.address))

const ticketUrl = computed(() => i18nGetLocalizedString(localizedData?.value?.ticket_url))

// 2
const defaultOgImageUrl = baseStrapiGetAbsoluteFileUrl(localizedData?.cover_image?.data?.attributes?.url, localizedData?.cover_image?.data?.provider)
pageSetHead(props?.pageData, defaultOgImageUrl)
</script>

<style module>
.accessibilityTypes {
  composes: font-size-small from global;
  color: var(--color--blue);
}

.showOnMapContainer {
  margin-top: var(--unit--vertical);
}

.tags {
  margin-top: var(--unit--vertical);
}

.address {
  composes: font-size-small from global;
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--spacer);
}

.linkToDirection {
  composes: reset-link link from global;
  color: var(--color--blue);
}

.linkToTickets {
  composes: reset-link font-size-small link from global;
  color: var(--color--blue);
}

.linkToAll {
  margin-left: calc(var(--unit--horizontal) * 2);
  margin-right: auto;
}
</style>
