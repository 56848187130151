import qs from 'qs'

export const queryClusterEntryBySlug = (options: QueryBySlugOrIdOptions) => {
  const searchKey = strapiGetI18nSlug(options.locale)
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      filters: {
        slugs: {
          [searchKey]: {
            $eq: options.slug
          }
        }
      },
      populate: {
        ...queryFragmentCollectionTypeDefault,
        ...queryFragmentBlocks,
        ...queryFragmentNewsEntries,
        ...queryFragmentSeo,
        cover_image: true,
        city_entries: {
          sort: [`${sortKey}:asc`],
          populate: {
            titles: true,
            slugs: true,
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `cluster-entries?${query}`
}
