<template>
  <header :class="$style.root">
    <div :class="$style.container">
      <div>
        <div :class="$style.label" v-if="i18nPrefix">
          {{ $t(`label.${i18nPrefix}`, { count: 1 }) }}
        </div>
        <h1
          :class="[$style.heading, fontSizeClass]"
        >
          {{ title }}
        </h1>
      </div>
      <slot name="close" />
    </div>

    <slot />
  </header>
</template>

<script setup>
import { camelCase } from 'lodash-es'
const route = useRoute()

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  i18nPrefix: String
})

const title = computed(() => i18nGetLocalizedTitle(props?.data))

const fontSizeClass = computed(() => {
  return 'font-size-large'
})

const routeName = computed(() =>{
  return camelCase(route.name?.split('___')[0] || '')
})
</script>

<style module>
.root {
}

.container {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: var(--unit--horizontal);
  align-items: start;
}

.label {
  composes: font-size-x-small from global;
  text-transform: uppercase;
  grid-area: label;
  margin-bottom: calc(var(--font-x-small--line-height) / 2);
  color: var(--color--gray--mid);
}

.heading {
  composes: reset-heading from global;
  grid-area: heading;
}
</style>
